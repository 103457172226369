import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled/macro';
import { useEffect, useMemo, useState, } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import isPropValid from '@emotion/is-prop-valid';
import { ReactComponent as PlusIcon } from '@icons/wolfkit-light/plus-light.svg';
import { PortfolioViewButtons, } from '@entities/portfolio';
import { IsDefined } from '@utils/js-ts';
import { ContainerColumn, ContainerRow } from '@src/components/styled';
import { TitleSecondary } from '@src/components/styled/Typography';
import { CreatePortfolioButton } from '@features/portfolio/portfolio-create';
import { PortfolioBadgeHeight } from '@entities/portfolio/ui/shared/PortfolioContainer';
import { sortPortfoliosByStatusPriority } from '../../lib';
import CarouselControls from './components/CarouselControls';
import CarouselCardContent from './components/CarouselContent/CarouselCardContent';
import CarouselListContent from './components/CarouselContent/CarouselListContent';
var CarouselViewType;
(function (CarouselViewType) {
    CarouselViewType["Card"] = "card";
    CarouselViewType["List"] = "list";
})(CarouselViewType || (CarouselViewType = {}));
const PortfoliosCarouselContainer = styled(ContainerColumn)((props) => ({
    height: 'auto',
    boxSizing: 'border-box',
    gap: props.theme.spacing_sizes.xm,
}));
const Headline = styled(ContainerRow)(() => ({
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'space-between',
}));
const PortfoliosContainer = styled(ContainerRow)(() => ({
    height: 'auto',
}));
const CarouselChunkContainer = styled(ContainerRow, {
    shouldForwardProp: propName => isPropValid(propName),
})((props) => (Object.assign({ height: 'auto', flexDirection: props.viewType === 'list' ? 'column' : 'row', gap: props.viewType === 'list' ? props.theme.spacing_sizes.m : props.theme.spacing_sizes.xm, alignItems: 'end' }, props.hasPortfoliosWithBadges && ({
    marginTop: PortfolioBadgeHeight,
}))));
const PortfoliosPerChunk = 3;
const getPortfolioCardViewType = (carouselViewType) => {
    let cardViewType = 'card';
    if (carouselViewType === CarouselViewType.Card) {
        cardViewType = 'card';
    }
    else if (carouselViewType === CarouselViewType.List) {
        cardViewType = 'list';
    }
    return cardViewType;
};
const PortfoliosCarousel = ({ portfolios, onSelectPortfolioId, onPortfolioCopy, selectedPortfolioId = undefined, maxCount = 10, showCreatePortfolioBtn = false, isLoading = false, }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const [portfolioToEdit, setPortfolioToEdit] = useState(undefined);
    const [currentView, setCurrentView] = useState(CarouselViewType.Card);
    const portfoliosSorted = useMemo(() => sortPortfoliosByStatusPriority(portfolios), [portfolios]);
    const hasPortfoliosWithBadges = useMemo(() => {
        for (let i = 0; i < portfoliosSorted.length; i += 1) {
            const portfolio = portfoliosSorted[i];
            if (!isLoading &&
                (portfolio.is_archived ||
                    !portfolio.assessmentPassed ||
                    portfolio.has_error)) {
                return true;
            }
        }
        return false;
    }, [portfoliosSorted, isLoading]);
    const carouselChunks = useMemo(() => {
        const chunks = [];
        let chunk = [];
        portfoliosSorted.forEach((portfolio, idx) => {
            if (idx > 0 && idx % PortfoliosPerChunk === 0) {
                chunks.push(chunk);
                chunk = [];
            }
            chunk.push(Object.assign({}, portfolio));
        });
        if (chunk.length) {
            chunks.push(chunk);
        }
        return chunks;
    }, [portfoliosSorted]);
    const selectedPortfolioCarouselChunkIndex = useMemo(() => {
        if (selectedPortfolioId && carouselChunks.length) {
            for (let chunkIndex = 0; chunkIndex < carouselChunks.length; chunkIndex += 1) {
                const chunk = carouselChunks[chunkIndex];
                for (let i = 0; i < chunk.length; i += 1) {
                    const portfolio = chunk[i];
                    if (portfolio.id === selectedPortfolioId) {
                        return chunkIndex;
                    }
                }
            }
        }
        return 0;
    }, [
        selectedPortfolioId,
        carouselChunks,
    ]);
    const [currentChunkIndex, setCurrentChunkIndex] = useState(selectedPortfolioCarouselChunkIndex);
    const onNavigateLeft = () => {
        const newIndex = currentChunkIndex - 1;
        if (carouselChunks[newIndex]) {
            setCurrentChunkIndex(newIndex);
        }
    };
    const onNavigateRight = () => {
        const newIndex = currentChunkIndex + 1;
        if (carouselChunks[newIndex]) {
            setCurrentChunkIndex(newIndex);
        }
    };
    const onNavigateToChunk = (idx) => {
        if (idx !== currentChunkIndex && !!carouselChunks[idx]) {
            setCurrentChunkIndex(idx);
        }
    };
    const selectPortfolio = (id) => {
        onSelectPortfolioId(id);
    };
    const onPortfolioAction = (event, actionType, portfolioId) => {
        if (actionType === 'edit') {
            setPortfolioToEdit(portfoliosSorted.find(p => p.id === portfolioId));
        }
        if (actionType === 'subscribe') {
            onPortfolioCopy(portfolioId);
        }
    };
    const onEditClose = () => {
        setPortfolioToEdit(undefined);
    };
    const handleViewChange = (viewType) => {
        switch (viewType) {
            case 'card':
                setCurrentView(CarouselViewType.Card);
                break;
            case 'list':
                setCurrentView(CarouselViewType.List);
                break;
            default:
                break;
        }
    };
    const currentChunk = useMemo(() => {
        let chunk = [];
        if (currentView === 'card' && carouselChunks[currentChunkIndex]) {
            chunk = carouselChunks[currentChunkIndex];
        }
        else if (currentView === 'list') {
            chunk = portfoliosSorted;
        }
        return chunk;
    }, [currentView, currentChunkIndex, carouselChunks, portfoliosSorted]);
    // NOTE: on selectedPortfolioId change we are setting carousel page to
    // the chunk where the selected portfolio is located
    useEffect(() => {
        setCurrentChunkIndex(selectedPortfolioCarouselChunkIndex);
    }, [selectedPortfolioCarouselChunkIndex]);
    const isLastChunk = currentChunkIndex === carouselChunks.length - 1;
    const displayCreatePortfolioBtn = 
    // we dont know how button looks like as list
    (currentView === 'card' || currentView === 'list') &&
        !IsDefined(portfolioToEdit) &&
        portfoliosSorted.length < maxCount &&
        isLastChunk && showCreatePortfolioBtn && !isLoading;
    return (_jsxs(PortfoliosCarouselContainer, { children: [_jsxs(Headline, { children: [_jsx(TitleSecondary, { children: `${t('profile.tabs.portfolios')} (${portfoliosSorted.length})` }), _jsx(PortfolioViewButtons, { activeView: currentView, onChangeView: handleViewChange })] }), _jsx(PortfoliosContainer, { children: _jsxs(CarouselChunkContainer, { viewType: currentView, hasPortfoliosWithBadges: hasPortfoliosWithBadges, children: [currentView === 'card' &&
                            (_jsx(CarouselCardContent, { onEditClose: onEditClose, onPortfolioAction: onPortfolioAction, onPortfolioSelect: selectPortfolio, portfoliosList: currentChunk, isLoading: isLoading, portfolioToEdit: portfolioToEdit, selectedPortfolioId: selectedPortfolioId })), currentView === 'list' &&
                            (_jsx(CarouselListContent, { onEditClose: onEditClose, onPortfolioAction: onPortfolioAction, onPortfolioSelect: selectPortfolio, portfoliosList: currentChunk, isLoading: isLoading, portfolioToEdit: portfolioToEdit, selectedPortfolioId: selectedPortfolioId })), displayCreatePortfolioBtn && (_jsx(CreatePortfolioButton, { style: {
                                width: currentView === 'card' ? 422 : '100%',
                                height: currentView === 'card' ? 308 : 78,
                                maxHeight: 'unset',
                                border: `2px dashed ${theme.palette.primary.main}`,
                            }, startIcon: PlusIcon, variant: 'tinted', children: t('overview.portfolios_list.create_portfolio') }))] }) }), currentView === 'card' &&
                carouselChunks.length > 1 &&
                (_jsx(CarouselControls, { pagesLength: carouselChunks.length, currentPageIndex: currentChunkIndex, disabled: IsDefined(portfolioToEdit), onNavigateLeft: onNavigateLeft, onNavigateRight: onNavigateRight, onNavigateToPageIndex: onNavigateToChunk }))] }));
};
export default PortfoliosCarousel;
